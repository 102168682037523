

class MailHandler {
  feedBacks = [
    {
      id: "form-signup",
      success: "Email sent successfully!",
      error: "Failed to send email. Please try again later."
    },
    {
      id: "form-request-demo",
      success: "Email sent successfully!",
      error: "Failed to send email. Please try again later."
    },
    {
      id: "form-follow-up",
      success: "Message sent successful! We will contact you soon!",
      error: "Failed to send email. Please try again later."
    },
    {
      id: "form-waitinglist",
      success: "Joining in waitlist successfully! We will contact you soon!",
      error: "Failed to send email. Please try again later."
    },
  ]

  sendMail = async (data) => {
    const { API_URL } = configEnv;
    try {
      await fetch(`${API_URL}/send-mail.php`, {
        method: "POST",
        body: data,
      })
      return true;
    } catch (error) {
      return false;
    }
  }

  sendMailFromForm = async (formId) => {
    if (!formId) {
      console.error("Form ID is missing");
      return;
    }
    const data = this.getDataFromFrom(formId);
    const formattedData = this.formatFormData(data);
    const mailSent = await this.sendMail(formattedData);
    const feedBack = this.feedBacks.find(feedBack => feedBack.id === formId);
    if (feedBack) {
      if (mailSent) {
        alert(feedBack.success);
      } else {
        alert(feedBack.error);
      }
    }
  }

  getDataFromFrom = (formId) => {
    const form = document.getElementById(formId);
    if (!form) {
      console.error("Form is missing");
      return;
    }
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    return data;
  }

  formatFormData = (data) => {
    if (!data || Object.keys(data).length === 0) {
      console.error("Data is missing");
      return;
    }
    let body = "";
    for (let key in data) {
      body += `${key.toUpperCase()}: ${data[key]}<br>`;
    }
    return body;
  }
}

const mailHandler = new MailHandler();